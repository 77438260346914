/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable require-jsdoc */
import jwt, { JwtPayload } from 'jsonwebtoken';
import { KeycloakProfile, KeycloakTokenParsed } from 'keycloak-js';

import { parseCookies } from './cookies';

export const KEYCLOAK_CONFIG = {
  clientSecret: process.env.NEXT_PUBLIC_KEYCLOAK_SECRET ?? '',
  clientId: process.env.NEXT_PUBLIC_KEYCLOAK_CLIENT_ID ?? '',
  issuer: process.env.NEXT_PUBLIC_KEYCLOAK_ISSUER ?? '',
};

export function getPayload(token: string) {
  return JSON.parse(
    Buffer.from(token.split('.')[1], 'base64').toString('utf8'),
  );
}

export function isTokenExpired(token: string) {
  const payload = getPayload(token);

  const clockTimestamp = Math.floor(Date.now() / 1000);

  return clockTimestamp > payload.exp;
}

export type Payload = KeycloakTokenParsed & KeycloakProfile;

export type Token = { token: string; payload: Payload };

type Request = { headers: { cookie?: any } };

export function verifyToken(token: string, key: string): JwtPayload | false {
  try {
    return jwt.verify(token, key, { ignoreExpiration: false }) as JwtPayload;
  } catch (e) {
    console.error(e, token, key);
    return false;
  }
}

export function getToken(cookies: any) {
  return Buffer.from(cookies.kcToken, 'base64').toString('utf8');
}

export function validateAuth(req?: Request): Token | boolean {
  const cookies = parseCookies(req);
  if (!cookies.kcToken) {
    return false;
  }
  const token = getToken(cookies);
  const payloadOrFalse = verifyToken(token, process.env.JWT_SECRET as string);
  return payloadOrFalse
    ? ({ token, payload: payloadOrFalse } as any)
    : payloadOrFalse;
}

export function getUrlAccount(): string {
  return process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
    ? 'https://account.devyx.com.br'
    : 'https://account.dev.devyx.com.br';
}

export function getBackendUrl(): string {
  return process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
    ? 'https://endpoint.devyx.com.br'
    : 'https://endpoint.dev.devyx.com.br';
}
